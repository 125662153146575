<template>
  <div class="xiaoxi">
    <ul>
      <li
        :class="{ classification: classification == index ,classification1:classification != index}"
        @click="Jump(index)"
        v-for="(item,index) in type"
        :key="index"
      >{{item}}</li>
    </ul>
    <div class="router">
      <router-view />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      classification: 0, ///选中状态
      type: ["我发布的", "我负责的", "我加工的"]
    };
  },
  created() {
    // alert(this.$route.path)
    ///获取当前路由
    if (this.$route.path == "/posted") {
      this.classification = 0;
    }
    if (this.$route.path == "/charge") {
      this.classification = 1;
    }
    if (this.$route.path == "/made") {
      this.classification = 2;
    }
  },
  methods: {
    Jump(index) {
      console.log("this.classification", this.classification, "index", index);
      if (this.classification != index) {
        if (index == 0) {
          this.$router.push("/posted");
        } else if (index == 1) {
          this.$router.push("/charge");
        } else if (index == 2) {
          this.$router.push("/made");
        }
      }
      this.classification = index;
    }
  }
};
</script>
<style scoped>
.xiaoxi {
  width: 100%;
  /* border: 1px solid blue; */
  /* display: inline-block;
  background-color: #ffffff; */
  /* margin-left: 10%;
  margin-right: 10%; */
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04); */

  /* margin: auto; */
}
ul {
  width: 93%;
  display: inline-block;
  /* margin-top: 20px;
  margin-bottom: 20px; */
  /* border: 1px solid red; */
  /* border-bottom: 1px solid red; */
}
ul li {
  border: 1px solid #141414;
  border-radius: 4px;
  width: 118px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  /* float: left; */
  display: inline-block;
  cursor: pointer;
  margin-left: 7%;
  margin-right: 7%;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #7d7d7d;
}
.classification1:hover {
  background-color: #ebf4fb;
}
.classification {
  /* 选中状态样式 */
  background: #0084ff;
  color: #ffffff;
}
.router {
  margin-top: 15px;
  border-top: 1px solid #afafaf;
  width: 100%;
  /* padding: 3% 3% 3% 3%; */
}
</style>
